<template>
  <b-container>
    <b-row>
      <b-col
        md="6"
        lg="4"
      >
        <b-card>
          <b-form @submit.prevent="handleSubmit()">
            <h3>Editar información de tienda</h3>
            <div class="d-flex justify-content-start my-2">
              <base-cropper
                :model="store"
                @cropped-image="store.logo = $event"
              />
            </div>

            <b-form-group
              class="mb-2"
              label="Nombre de la tienda"
            >
              <b-form-input
                v-model="store.name"
                required
              />
            </b-form-group>

            <b-form-group
              class="mb-2"
              label="¿Vendes en linea o en tu tienda fisica?"
            >
              <b-form-select
                v-model="store.category"
                :options="storeCategory"
              />
            </b-form-group>

            <b-form-group
              class="mb-2"
              label="Tipo de tienda"
            >
              <b-form-select
                v-model="store.store_type"
                :options="storeType"
              />
            </b-form-group>

            <b-form-group
              class="mb-2"
              label="¿A qué hora cierras?"
            >
              <v-select
                v-model="store.end_of_day_restocking_time"
                :options="timeArray"
                :reduce="val => val.type"
                label="name"
                clearable
                placeholder="Selecciona el mejor horario para ti"
              />
            </b-form-group>

            <b-form-group
              class="mb-2"
              label="Mensaje de cobro para ventas en linea"
            >
              <b-form-input
                v-model="store.codi_message"
                required
              />
            </b-form-group>
            <b-form-group
              label="Selecciona los tipos de pago que acepta esta tienda para ventas en linea"
              label-for="is-edible"
            >
              <b-form-checkbox
                id="cash"
                v-model="acceptedPaymentTypes"
                class="custom-control-primary"
                name="check-button"
                value="cash"
                switch
              >
                Efectivo
              </b-form-checkbox>
              <b-form-checkbox
                id="walleat"
                v-model="acceptedPaymentTypes"
                class="custom-control-primary"
                name="check-button"
                value="walleat"
                switch
              >
                Walleat Pay
              </b-form-checkbox>
              <b-form-checkbox
                id="bankcard"
                v-model="acceptedPaymentTypes"
                class="custom-control-primary"
                name="check-button"
                value="bankcard"
                switch
              >
                Tarjeta bancaria
              </b-form-checkbox>
              <b-form-checkbox
                id="reward_points"
                v-model="acceptedPaymentTypes"
                class="custom-control-primary"
                name="check-button"
                value="reward_points"
                switch
              >
                Puntos de recompensa
              </b-form-checkbox>
              <b-form-checkbox
                id="bank_deposit"
                v-model="acceptedPaymentTypes"
                class="custom-control-primary"
                name="check-button"
                value="bank_deposit"
                switch
              >
                Transferencia o depósito bancario
              </b-form-checkbox>
              <b-form-checkbox
                id="codi"
                v-model="acceptedPaymentTypes"
                class="custom-control-primary d-flex"
                name="check-button"
                value="codi"
                switch
              >
                CoDi
              </b-form-checkbox>
            </b-form-group>
            <div v-show="keepOldAddress">
              <b-col>
                <p class="d-flex flex-column">
                  <span class="text-muted">
                    ¿La dirección de tu tienda es la siguiente? Si no, modifícala
                  </span>
                  <span v-if="store.address_attributes">
                    {{ store.address_attributes.street }} {{ store.address_attributes.ext_number }},
                    CP {{ store.address_attributes.postal_code }}, {{ store.address_attributes.city }},
                    {{ store.address_attributes.state }}, {{ store.address_attributes.country }}
                  </span>
                </p>
                <b-button
                  class="mb-3"
                  @click="changeAddress()"
                >
                  Modificar dirección
                </b-button>
              </b-col>
            </div>
            <div v-show="!keepOldAddress">
              <address-form-model
                info-type
                @getAddress="setAddressForEdit($event)"
              />
            </div>
            <b-button
              class="mr-1"
              variant="primary"
              type="submit"
            >
              Guardar
            </b-button>
            <b-button
              variant="outline-secondary"
              :to="{ name: 'establishment-sucursales', params: { id: $route.params.id } }"
            >
              Cancelar
            </b-button>
          </b-form>
        </b-card>
      </b-col>
      <b-col>
        <div class="text-center">
          <b-img
            :src="require('@/assets/images/illustration/pricing-Illustration.svg')"
            width="600"
            fluid
          />
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import AddressFormModel from '@/@core/components/AddressFormModel.vue'
import {
  BContainer,
  BCard,
  BButton,
  BForm,
  BRow,
  BCol,
  BFormInput,
  BFormGroup,
  BImg,
} from 'bootstrap-vue'
import { mapActions } from 'vuex'
import BaseCropper from '@/@core/components/BaseCropper.vue'
import vSelect from 'vue-select'

export default {
  components: {
    AddressFormModel,
    BContainer,
    BCard,
    BButton,
    BForm,
    BRow,
    BCol,
    BFormInput,
    BFormGroup,
    BImg,
    vSelect,
    BaseCropper,
  },
  data() {
    return {
      acceptedPaymentTypes: [],
      timeArray: [],
      store: {
        establishment_id: this.$route.params.id,
        name: null,
        logo: null,
        category: null,
        store_type: null,
        codi_message: null,
        accepted_payment_types: [],
        address_attributes: {},
        end_of_day_restocking_time: null,
      },
      storeCategory: [
        { value: 'physical', text: 'Tienda fisica' },
        { value: 'online', text: 'Tienda en línea' },
        { value: 'hybrid', text: 'Tienda híbrida' },
      ],
      storeType: [
        { value: 'corner_shop', text: 'Tiendita de la esquina' },
        { value: 'school_cafeteria', text: 'Cafetería de la escuela' },
        { value: 'flower_store', text: 'Floreria' },
        { value: 'book_store', text: 'Librería' },
        { value: 'hardware_store', text: 'Ferretería' },
        { value: 'tortilleria', text: 'Tortillería' },
        { value: 'coffee_shop', text: 'Cafetería' },
        { value: 'clothing_store', text: 'Tienda de ropa' },
        { value: 'water_purifier', text: 'Purificadora de agua' },
        { value: 'corporate_education', text: 'Educación corporativa' },
        { value: 'cleaning_hardware', text: 'Productos de limpieza' },
        { value: 'other', text: 'Otra' },
      ],
      keepOldAddress: true,
    }
  },
  mounted() {
    if (this.$route.params.store_id) {
      this.fetchStore(this.$route.params.store_id)
        .then(response => {
          this.store = response
          this.addressId = `${this.store.address_attributes.id}`
          this.acceptedPaymentTypes = this.store.accepted_payment_types
          this.formattedClosingTime()
        })
    }
    this.generateTimeArray()
   
  },
  methods: {
    ...mapActions('stores', [
      'editStore',
      'fetchStore',
    ]),

    generateTimeArray() {
      for (let i = 0; i < 24; i++) {
        const hour = i < 10 ? `0${i}` : `${i}`;
        const timeObject = {
          type: `${hour}:00:00`,
          name: i === 0 ? '12:00 AM' : i < 12 ? `${i}:00 AM` : i === 12 ? '12:00 PM' : `${i - 12}:00 PM`,
        };
        this.timeArray.push(timeObject);
      }
    },

    formattedClosingTime() {
      if (this.store.end_of_day_restocking_time) {
        // Extract hours and minutes from the string
        const hoursAndMinutes = this.store.end_of_day_restocking_time.slice(11, 19);
        this.store.end_of_day_restocking_time = hoursAndMinutes
      }
    },

    setAddressForEdit(x) {
      this.store.address_attributes = x
    },
    changeAddress() {
      this.keepOldAddress = !this.keepOldAddress
    },
    handleSubmit() {
      const store = {
        name: this.store.name,
        establishment_id: this.$route.params.id,
        logo: this.store.logo,
        category: this.store.category,
        store_type: this.store.store_type,
        end_of_day_restocking_time: this.store.end_of_day_restocking_time,
        address_attributes: this.store.address_attributes,
        codi_message: this.store.codi_message,
        accepted_payment_types: this.acceptedPaymentTypes,
      }
      // TODO: Manu Revisar que onda con el PUT
      this.store.address_attributes.id = `${this.addressId}`
      this.editStore({ id: this.$route.params.store_id, store })
        .then(() => {
          this.$router.push({ name: 'establishment-sucursales', params: { id: this.$route.params.id } })
        })
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
